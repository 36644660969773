@import '../../../variables'

.schedule-selector
  .MuiDialog-container
    .MuiPaper-root
      width: 650px
      max-width: 100%
      row-gap: 10px
      max-height: 100%
      margin: 0

  .MuiDialogActions-root
    justify-content: center !important
    padding: 30px 54px 40px 54px

.dialog-schedule-selector-content
  display: flex
  flex-direction: column
  max-width: 600px
  padding: 0 10px 20px !important

.schedule-selector-header
  width: 100%
  display: flex
  justify-content: space-between
  align-items: baseline
  column-gap: 20px
  h2
    font-style: normal
    font-weight: 800
    font-size: 22px
    line-height: 30px
    display: flex
    align-items: center
    color: $parso-high-gray
  svg
    width: 32px
    height: 32px
    path
      fill: $parso-blue
.schedule-selector-legend-container
  margin-bottom: 20px

.schedule-selector-legend
  font-size: 14px
  color: $parso-super-strong-gray
  display: flex
  align-items: baseline
  column-gap: 10px
  svg
    width: 12px
    height: 12px
  &.unavailable
    svg
      circle
        fill: $parso-danger-light
  &.available
    svg
      circle
        fill: $parso-success
  &.calendar
    svg
      circle
        fill: $parso-green-blue

.book-room-button
  background: $parso-blue
  width: 100%
  height: 52px
  border-radius: 8px !important
  font-size: 18px !important

@media screen and (min-width: $sm)
  .schedule-selector
    .MuiDialog-container
      .MuiPaper-root
        max-height: calc( 100% - 64px )
        margin: 32px

  .MuiDialogTitle-root
    padding: 40px 57px 0

  .dialog-schedule-selector-content
    padding: 0 54px 20px !important

  .schedule-selector-header
    align-items: stretch
