@import '../../../variables'

.RoomCard.RoomCard
  position: relative
  background: white
  box-shadow: 0px 0px 5px rgb(216 216 216 / 75%)
  border-radius: 10px
  width: 100%
  max-width: 328px
  cursor: pointer
  margin: 5px
  &.selected
    box-shadow: 0px 0px 5px var(--primary)

  .MuiCardContent-root
    padding: 12px
    display: flex
    flex-direction: column
    align-items: normal
    width: 303px
    height: 140px
  h1
    font-style: normal
    font-weight: 600
    font-size: 20px
    line-height: 34px
    align-items: center
    letter-spacing: 0.0036em
    color: #181818
    max-width: 38%
    overflow: hidden
    text-wrap: nowrap
    text-overflow: ellipsis

  ul
    list-style: none
    margin: 8px 0 0 0
    padding: 0
    font-weight: 400
    font-size: 14px
    line-height: 22px
    align-items: center
    letter-spacing: -0.0041em
    color: $parso-black-ligth-text

    li
      margin-bottom: 8px
      width: 95%
      overflow: hidden
      text-wrap: nowrap
      text-overflow: ellipsis

  .info
    width: 100%

  .buttons-container
    width: 312px
    display: flex
    justify-content: space-around
    bottom: 14px
    position: absolute

    .see-details
      color: $parso-blue
      font-size: 14px
      font-style: normal
      line-height: normal
      font-weight: 200
      padding: 0
      &:hover
        background: none

  @media screen and (min-width: $sm)
    .MuiCardContent-root
      h1
        max-width: 48%
