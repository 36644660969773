@import '../../../variables'

.stepper-container
  width: 220px
  align-items: center !important
  gap: 5px
  position: relative

  .MuiStepConnector-root
    position: absolute
    top: 50%
    left: calc(-65% + 20px)
    right: calc(40% + 20px)
    transform: translateY(-50%)
    z-index: 1

    .MuiStepConnector-line
      border-color: var(--primary)
      border-top-width: 2px
      border-radius: 1px

  .custom-step-icon 
    display: flex
    align-items: center
    justify-content: center
    width: 23px
    height: 23px
    border-radius: 50%
    border: 2px solid var(--primary)
    color: var(--primary)
    background-color: #fff
    z-index: 2

    &.active
      width: 49px
      height: 49px
      .step-icon-text 
        font-size: 24px
        font-weight: 600

    &.completed
      background-color: var(--primary)
      color: #fff

    .step-icon-text
      font-family: 'Nunito', sans-serif
      font-size: 15px

  @media screen and (min-width: $md)
    width: 235px

