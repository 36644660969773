@import '../../../variables'
.add-filters
	.MuiPaper-root
		max-height: calc(100% - 50px)
		min-height: calc(100% - 240px)

	.MuiAccordion-root:before
		background-color: transparent !important
	
	.MuiAccordionSummary-root
		min-height: 44px !important

	.MuiDivider-root
		margin: 0 16px !important

	.add-filters-header
		display: flex
		column-gap: 24px
		align-items: center
		h1
			font-size: 22px
			color: $parso-high-gray
		.back-button
			color: $parso-blue
			&:hover
				background: none
	
	.input-label
		display: flex
		align-items: flex-start
		column-gap: 8px
		font-size: 16px
		color: $parso-medium-gray

	.MuiSelect-select
		padding-top: 8px

	.MuiFormControl-root
		row-gap: 8px

	.occupancy-capacity-filter
		display: flex
		flex-direction: row
		justify-content: space-between
		align-items: center
		flex-wrap: wrap
	
	.occupancy-capacity-message
		color: $parso-green-blue
		font-size: 14px
		display: flex
		gap: 5px
		align-items: center
		&.max-capacity-reached
			color: $parso-text-error
	
	.add-filter-actions
		padding: 12px 30px 40px 30px
		display: flex
		gap: 8px
		flex-direction: column
		align-items: center

		button
			display: flex
			height: 52px
			padding: 12px 24px
			justify-content: center
			align-items: center
			gap: 8px
			border-radius: 8px
			flex: 1 0 0
			font-size: 18px
			width: 100%
			margin: 0 !important
	
	.amenity-chip
		&.MuiChip-filled
			background-color: #EEF2FF
			border: 1px solid #6C85D3
			color: #6C85D3 !important
			& svg > path
				fill: #6C85D3

	@media screen and (min-width: $sm)
		.add-filter-actions
			flex-direction: row
			padding: 0 30px 52px 30px
