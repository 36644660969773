@import '../../../variables'

.WorkplaceReservation
  .attendees-error-message
    border-radius: 0px 8px 8px 0px
    border-left: 1.5px solid #CD2A26
    background: rgba(207, 112, 111, 0.10)
    color: $parso-danger-light
    display: flex
    padding: 8px 10px
    align-items: flex-start
    gap: 10px
    align-self: stretch
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-top: 24px
  
  .MuiButton-root.MuiButton-root
    min-width: 316px
    font-family: 'Avenir', sans-serif
    letter-spacing: 1px
