@import '../../variables'

.Settings
  width: 316px
  margin-top: 39px
  label
    color: $parso-high-gray
  .filled-select
    width: 162px
    .MuiSelect-select
      padding-top: 8px

  &.horizontal
    max-width: 580px
    width: 100%

    label
      color: $parso-blue-strong
      font-size: 16px
      font-style: normal
      font-weight: 700
      line-height: normal

    p
      color: $parso-high-gray
      font-size: 11px
      font-style: italic
      font-weight: 300
      line-height: normal
      width: 284px
