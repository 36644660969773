@import '../../../variables'

.CardWrapper
  display: flex
  height: calc(100vh - 296px)
  overflow-y: scroll
  padding: 2px
  flex-flow: column
  align-content: baseline
  gap: 28px
  .cp-card
    & > div
      padding: 18px
    .card-wrapper-footer
      padding: 0
      display: flex
      justify-content: space-evenly
      padding-bottom: 10px
    .hr-title
      display: none
    ul
      list-style: none
      margin: 0
      padding: 0

    .min-div, .info-div
      display: inline-block
      vertical-align: top
    .min-div
      width: 50px
      text-align: center
      border-right: 2px solid #D1DEEF
      box-sizing: border-box
      margin-right: 15px
      padding-right: 13px
      .min-date, .min-month
        color: #6F6F6F
      .min-date
        font-size: 24px
        margin-bottom: 3px
      .min-month
        font-size: 11px
    .info-div
      width: calc(100% - 65px)

    &.QR
      background: $parso-super-light-blue
      min-height: 101px
      &>div:first-of-type
        padding: 0
        height: 100%

      svg
        font-size: 75px
        color: var(--primary)
      h3
        font-family: "Nunito Sans"
        font-size: 24px
        font-style: normal
        font-weight: 700
        color: $parso-high-gray

    &.REJECTED,
    &.RELEASED
      background: $parso-disabled-gray
      .title-card, .reason-card, .item-list li, .min-div
        color: $parso-medium-gray
      .title-card path
        fill: $parso-medium-gray
      .cancel-reservation
        color: $parso-medium-gray

  .item-list
    li
      font-size: 11.44px
      line-height: 19px
      color: $parso-high-gray
      font-weight: 400
      margin-bottom: 3px
      overflow: hidden
      text-wrap: nowrap
      text-overflow: ellipsis

      .car-item path
        fill: #8C8CA1

      svg
        margin-right: 11px
        vertical-align: middle

  .cancel-reservation
    justify-content: center
    color: $parso-danger-light
    font-size: 13px
    &:hover
      background: #cf716f2d
      font-weight: 800

    &.MuiCardActions-root
      padding: 7px 0 11px

  .title-card
    font-size: 12px
    font-weight: 500
    line-height: 19px
    margin-bottom: 5px
    svg
      float: right
      width: 8px

  .date-card
    display: none
    color: $parso-black-ligth-text
    line-height: 22px
    font-size: 11px

  .reason-card
    display: none
    color: $parso-high-gray
    line-height: 22px
    font-size: 18px
    margin-bottom: 11px

  .mark-status-button
    font-family: 'Nunito Sans', sans-serif
    font-size: 14px
    font-weight: 400
    border-radius: 8px

  .order-detail-list
    display: flex
    align-items: center

  @media screen and (min-width: $md)
    height: calc(100vh - 255px)
    padding: 4px
    flex-flow: wrap

    .title-card
      font-size: 18px
      line-height: 22px
      margin-bottom: 5px

    .cp-card
      .hr-title
        display: block
        margin-bottom: 13px
        width: 53px
        border-bottom: 2px solid var(--tertiary)
      &> div:first-of-type
        padding: 23px 28px 10px 9px
        min-height: 252px
        &:hover
          background: #6c86d31a

      .min-div
        border-right: 0
        box-sizing: border-box
        margin-right: 0
        padding-right: 0
        color: var(--secundary)
        margin-top: 20px

      .info-div
        width: calc(100% - 50px)

    .item-list
      li
        font-size: 13px
        padding-bottom: 6px
        line-height: 14.52px
    .date-card
      display: block

    .reason-card
      display: block
