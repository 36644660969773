@import '../../../variables'

.swipeable-timepicker.MuiDrawer-root
  z-index: 10000
  .MuiPaper-root
    height: 300px
    overflow: visible
    border-radius: 5px 5px 0 0

  &.range > .MuiPaper-root
    height: 530px

.SwipeableTimePicker
  max-width: 321px
  margin: auto

  .badge-calendar
    position: relative

    &.today .MuiBadge-badge
      color: white

    .MuiBadge-badge
      color: $parso-high-gray
      position: absolute
      top: 16px
      right: 20px
      font-size: 18px
      font-weight: bold
      cursor: pointer
      padding: 0
      text-align: center
      height: 2px
      width: 2px
      display: block

  .message
    margin-top: 17px
    margin-bottom: 10px
    font-style: normal
    font-weight: 500
    font-size: 11px
    line-height: 20px
    letter-spacing: -0.0041em
    height: 40px
    overflow: hidden
    display: block
    align-items: center

  .calendar-footer
    position: relative
    width: 279px
    margin: auto

    .stack-messages
      margin: 20px 0 15px

  @media screen and (min-width: $md)
    margin: 25px 30px 26px
