@import '../../../variables'
.dish-detail-modal
	height: calc(100vh - 40px)
	.MuiPaper-root
		background: linear-gradient(157deg, #FFF 0%, #F3F5FC 100%)
	.dish-detail-image
		width: 100%
		height: 200px
		background-size: cover
		background-position: center
		border-radius: 0px 0px 12px 12px

		.dish-detail-close-button, .dish-detail-back-button
			color: $parso-blue
			background: $parso-white
			width: 32px
			height: 32px
		
	.dish-detail-add-button
		padding: 12px 24px 
		width: 50% 
		height: 52px 
		border-radius: 8px 
		font-size: 18px 

	.meal-category-chip
		font-sixe: 12px
		background-color: $parso-light-green
		color: #128724
		font-weight: 400
		.MuiChip-label
			padding: 2px 8px
	
	.dish-detail-footer
		width: 100%
		display: flex
		flex-direction: row
		column-gap: 16px
		.counter-container
			background: $parso-white
			max-width: 124px