@import '../../../variables'

.ErrorBoundary
  .refresh-box
    background: white
    position: fixed
    bottom: 35px
    width: 100%
    padding-bottom: 20px
    .button-refresh
      margin: auto
    p
      color: $parso-high-gray-blue
      text-align: center
      font-size: 18px

  .image-box
    max-width: 719px
    max-height: 410px
    left: 0
    right: 0
    bottom: 0
    top: 0
    margin: auto
    position: absolute

    .text-wrapper
      position: absolute
      top: 30px
      left: 0
      right: 0

    img
      width: 90%
      margin: auto
      display: block
      position: absolute
      top: -77px
      bottom: 0
      margin: auto
      left: 0
      right: 0

    h1,h2
      color: $parso-high-gray-blue
      font-style: normal
      font-weight: 400
      text-align: center
    h1
      font-size: 33px
      line-height: 56px
    h2
      font-size: 18px

  @media screen and (min-width: $md)
    .image-box
      max-height: 515px

      .text-wrapper
        top: 20px
      h1
        font-size: 50px
        line-height: 68px
      h2
        font-size: 30px
        line-height: 41px

    .refresh-box
      padding-bottom: 60px
      p
        font-size: 26px
