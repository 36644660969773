@import '../../variables'
.NewVisitor.New-reservation
  .div-reservation.slide0
    margin-top: 0
  box-shadow: 0px 0px 6px $parso-shadow-color
  
  .MuiButton-root.MuiButton-root
    min-width: 316px
    font-family: 'Avenir', sans-serif
    letter-spacing: 1px
    text-transform: uppercase

.stack-import
  width: 100%
  .pre-label-form
    margin-bottom: 0

  .form-input-group
    width: 100%
    &>.MuiGrid-item
      padding: 0px 0px 10px 0

.visitor-label
  width: 180px
  font-size: 13px

.visitor-icon
  color: #D2D2D2
  width: 20px !important

.visitor-input, .company-selector
  width: 100%
  .MuiFilledInput-root
    height: 26px

.visitor-icon.white
  color: white

.import-info
  font-size: 14px
  line-height: 19px
  width: 225px
  min-width: 225px
  ul
    padding-left: 17px
    margin-top: 10px
  a
    color: $parso-download-blue
    text-decoration: none
    font-size: 18px
    svg
      margin-right: 8px

.new-reservation-footer
  button
    text-transform: uppercase

@media screen and (min-width: $md)
  .visitor-label
    width: 130px

  .visitor-input, .company-selector
    width: 259px

  .invitation-notes
    .pre-label-form
      margin-bottom: 12px
    .MuiInputLabel-root.notes-label
      display: none

  .stack-import
    .form-input-group
      width: inherit
      &>.MuiGrid-item
        padding: inherit
  .new-reservation-footer
    button
      max-width: 311px
