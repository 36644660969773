@import '../../../variables'

.CafeteriaReservation
	.info-qr-text
		color: $parso-green-blue
		font-size: 14px
		svg
			color: $parso-green-blue
	
	.MuiButton-root.MuiButton-root
		min-width: 316px
		font-family: 'Avenir', sans-serif
		letter-spacing: 1px
		text-transform: uppercase
