@import '../../../variables'
.dish-card
	display: flex
	width: 300px
	min-height: 140px
	padding: 16px
	column-gap: 12px

	.dish-card-image-container
		display: flex
		justify-content: center
		align-items: center

	.dish-card-image
		width: 92px
		height: 105px
		border-radius: 10px
	
	.dish-card-content
		padding: 0 
		width: 100%

	.dish-card-content > .see-details
		display: flex
		justify-content: left
	
	.meal-category-chip
		font-sixe: 12px
		background-color: $parso-light-green
		color: #128724
		font-weight: 400
		.MuiChip-label
			padding: 2px 8px

	.MuiBadge-badge
		font-size: 14px
		padding: 8px 
		background-color: $parso-success 
		height: 32px 
		width: 31px 
		border-radius: 24px
		color: #FAFAFAFA
		font-family: 'Nunito Sans', sans-serif 
		font-weight: 600 



