@import '../../../variables'
.room-list-modal
  .dialog-detail-content
    overflow: hidden
    padding: 40px 100px
    display: flex
    flex-direction: column
    align-items: center
    h2
      font-family: 'Nunito Sans'
      font-style: normal
      font-weight: 800
      font-size: 22px
      line-height: 30px
      display: flex
      align-items: center
      color: $parso-high-gray
      margin-bottom: 30px
      flex-direction: column

    .rooms-modal-header, .rooms-modal-searchbar-container
      width: 100%
      display: flex
      justify-content: space-between
      align-items: baseline
      gap: 15px

    .rooms-modal-searchbar-container
      flex-wrap: wrap
      align-items: center

    .rooms-modal-header button
      color: $parso-blue

    .rooms-modal-header svg
      width: 32px
      height: 32px

    .rooms-modal-searchbar-container
      margin-bottom: 20px

    .rooms-modal-searchbar-container input
      padding: 12px 16px 12px 8px
      &.MuiInputBase-input::placeholder
        font-family: 'Nunito Sans' !important
        font-size: 16px
        font-style: normal
        font-weight: 600
        line-height: normal
        color: #181818

    .MuiFilledInput-root.MuiFilledInput-root
      border-radius: 8px

    .MuiFilledInput-root.MuiFilledInput-underline::before
      border-bottom: 0px !important

    .search-input-adornment
      padding-left: 16px
      margin: 16px 0

    .add-filter-button-container
      position: relative
      & > svg
        position: absolute
        top: 5px
        left: 5px
        width: 11px
        z-index: 1
        circle
          fill: #34C700
    .rooms-modal-room-avalability-filter-container
      display: flex
      flex-direction: column
      row-gap: 8px
      width: 100%
      margin-bottom: 20px
      span
        font-size: 14px
        font-weight: 600

    .availability-filters
      display: flex
      justify-content: space-between

    .availability-filter-button
      border-radius: 8px !important
      width: 30%
      font-family: 'Nunito Sans' !important
      padding: 6px

    .rooms-modal-continue-button
      width: 358px
      height: 52px
      border-radius: 8px !important
      font-size: 18px !important

    .room-list
      height: calc(100vh - 427px)
      overflow: scroll
      width: 100%

      .no-rooms-available
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        row-gap: 16px
        h2
          max-width: 540px
          text-align: center
          .MuiSvgIcon-root
            width: 64px
            height: 64px
            color: $parso-medium-gray

    .room-card-availability-label
      position: absolute
      right: 0
      top: 0
      width: 156px
      font-size: 13px
      line-height: 22px
      color: #128724
      background: rgba(236, 250, 243, 1)
      border-radius: 0px 10px
      padding: 8px
      text-align: center
      font-weight: 600
      &.partially-available
        color: $parso-yellow-strong
        background: rgba(255, 248, 237, 1)
      &.not-available
        color: #CD2A26
        background: rgba(255, 235, 237, 1)

    .new-reservation-footer
      margin-top: 18px
      display: flex
      width: 100%
      justify-content: center

    @media screen and (min-width: $sm)
      .rooms-modal-header, .rooms-modal-searchbar-container
        row-gap: 1vh

        .availability-filter-button
          padding: 6px 16px

    @media screen and (min-width: $md)
      .rooms-modal-searchbar-container
        flex-wrap: nowrap

        .new-reservation-footer
          justify-content: flex-end
