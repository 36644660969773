@import '../../../variables'

.VehiclesModal
  .complete-form-wrapper
    max-height: calc( 100vh - 200px )
    overflow-y: scroll
  .form-vehicles-wrapper
    margin-bottom: 20px

  .add-new-vehicle
    margin-bottom: 40px
  .add-vehicle
    color: $parso-high-gray
    font-size: 14px

  @media screen and (min-width: $md)
    .complete-form-wrapper
      max-height: calc( 100vh - 300px )
