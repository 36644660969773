@import '../../../variables'

.StatusChip
  margin-bottom: 8px
  &.PENDING
    background: #FFF8ED
    color: $parso-warning
  &.APPROVED
    background: $parso-light-green
    color: #128724
  &.REJECTED
    color: #CD2A26
    background: #FFEBED
