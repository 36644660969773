@import '../../../variables'

.alert-dialog
	h2
		font-size: 20px
	.MuiPaper-root
		padding: 16px
		border-radius: 16px
		row-gap: 24px
	.alert-dialog-title, .alert-dialog-actions
		padding: 0
	.close-button-container
		display: flex
		justify-content: flex-end
		width: 100%
	.info-icon
		width: 56px
		height: 56px
	.alert-dialog-actions
		display: flex
		width: 100%
		gap: 16px
		justify-content: center
		button
			padding: 12px 24px
			width: 100%
			border-radius: 8px
	
	@media screen and (min-width: $sm)
		.alert-dialog-actions
			button
				width: 45%