@import '../../variables'
.dialog-reset-password
  .MuiDialogContent-root.MuiDialogContent-root
    padding: 166px 22px
    background-color: $parso-modal-gray
    .dialog-content-inner
      max-width: 400px
      margin: auto
    h1
      font-size: 24px
      text-align: center
    .MuiButtonBase-root.close-button
      position: absolute
      top: 16px
      right: 16px
      color: black
    .MuiFormControl-root
      margin: 36px 0 37px
    .cp-button
      font-size: 15px
      font-weight: 800
      background: $parso-gray-blue
      &.Mui-disabled
        color: white
        background: $parso-gray-blue
  @media screen and (min-width: $md)
    .MuiDialogContent-root.MuiDialogContent-root
      padding: 166px 100px
      h1
        font-size: 32px
      .MuiFormControl-root
        margin: 47px 0 59px
      .MuiButtonBase-root.close-button
        top: 36px
        right: 51px

.Login
  background: #FAFCFE
  color: $parso-high-gray-blue
  height: 100vh
  grid-template-columns: 60% 40%
  min-height: 560px
  .full-logo
    display: none
  .privacy
    color: $parso-high-gray-blue
    text-decoration: none
    font-size: 11px
    padding-top: 18px
  .cover
    display: none
  .login-inner
    height: 416px
    padding: 0 21px 47px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    max-width: 400px
    text-align: center
    .logo
      width: 48px
      margin-bottom: 29px
    h1
      color: $parso-high-gray-blue
      margin-bottom: 27px
    .MuiButton-text
      color: $parso-high-gray-blue
      text-decoration: underline
      font-size: 12px
      font-weight: 500
      line-height: 22px
      margin-bottom: 12px !important
    .cp-text-field
      .MuiInput-input, .MuiInputLabel-root
        padding: 16px 16px 16px 0
        font-weight: 500
        font-size: 16px
        letter-spacing: 0.15px
      .MuiInput-input
        color: #0E0E2C
      .MuiInputLabel-root
        color: #8C8CA1
    .cp-button
      font-weight: 800
      box-shadow: none
      letter-spacing: 0.075em
  @media screen and (min-width: $md)
    background: url(../../../public/login-background.svg) no-repeat center center fixed 
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover
    .full-logo
      display: block
      width: 180px
      position: relative
      top: 63px
      left: 65px
    .privacy
      color: #8C8CA1
    .login-inner
      background-color: white
      box-shadow: 0px 0px 60px rgba(108, 133, 211, 0.15)
      border-radius: 18px
      color: $parso-blue
      width: 530px
      max-width: 530px
      padding: 98px 75px 60px
      box-sizing: border-box
      height: fit-content
      .logo
        display: none
      h1
        text-align: left
        font-size: 32px
        margin-bottom: 52px
        color: $parso-blue
      .cp-span
        text-align: left
        display: block
        .MuiButton-text
          color: $parso-blue
          float: right
          margin-bottom: 0 !important
          padding: 0
      .cp-label
        font-weight: 800
        text-align: left
        font-size: 18px
        color: $parso-blue
      .cp-text-field
        &:first-of-type
          margin-bottom: 51px
        .MuiFilledInput-root
          background: transparent !important
          border-radius: 6px
          margin-bottom: 6px
          & ~.MuiFormHelperText-root
            padding: 2px 10px
            margin: 19px 0 0 0
            background: #E0778AB2 !important
            border-radius: 8px
            color: white
          &.Mui-focused
            background: #E7EEFF33 !important
            font-weight: 500
          &::before, &::after
            bottom: -6px
            border-bottom: 1px solid #FFFFFF80 !important
        .MuiIconButton-root
          path
            fill: white
        .MuiFilledInput-input
          padding: 3px 9px 3px 0
          color: #F7F7F780
          font-size: 16px
          &:focus
            padding-left: 9px
            font-weight: 500
            color: $parso-light-blue-gray
        .MuiInput-root
          path
            fill: #8C8CA1
      .cp-button
        margin-top: 76px
        font-size: 15px
        background: #E7EEFF
        color: $parso-blue
        transition: margin-top ease 0.5s
        &.Mui-disabled
          background: rgba(209, 222, 239, 0.3) !important

        &.next
          margin-top: 0
