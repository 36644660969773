@import '../../variables'

.Information
  .information-inner
    max-width: 568px
    .item-wrapper
      padding: 14px 7px
      &:first-of-type
        margin-top: 11px
      h2
        font-weight: 800
        font-size: 14px
        margin-bottom: 3px
        line-height: 20px
        color: #525252
        line-height: 20px
      h3
        color: #8C8CA1
        font-size: 12px
        line-height: 16px
      .item-icon
        float: right
        padding: 5px
  @media screen and (min-width: $md)
    .information-inner
      .item-wrapper
        padding-left: 0px
        h2
          font-weight: 500
          font-size: 16px
          line-height: 22px
        h3
          color: #8C8CA1
          font-size: 16px
          line-height: 22px
