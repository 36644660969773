@import '../../../variables'
.room-details
    .MuiDialog-paper
        width: 70vh
    .room-details-header
        display: flex
        column-gap: 24px
        align-items: center
        h1
            font-size: 22px
            color: $parso-high-gray
        .back-button
            color: $parso-blue
            &:hover
                background: none

    .room-image
        border-radius: 10px
        aspect-ratio: 16/9

    .image-placeholder
        width: 474px
        aspect-ratio: 16/9
        
    .room-details-info
        h2
            font-size: 20px
            color:$parso-denied
            font-weight: 600
        
        ul
            list-style: none
            margin: 8px 0 0 0
            padding: 0
            font-weight: 400
            font-size: 18px
            line-height: 22px
            align-items: center
            letter-spacing: -0.0041em
        
        li:first-child
        margin-bottom: 8px

    .amenities-list
        font-size: 18px
        color: $parso-high-gray
        font-weight: 400

    .MuiDialogActions-root
        justify-content: center !important
        padding: 0 !important
        margin-top: 60px

    .book-room-button
        width: 100%
        height: 52px
        border-radius: 8px !important
        font-size: 18px !important
