@import '../../../variables'

.MuiTooltip-tooltip.MuiTooltip-tooltip
  background-color: $parso-white
  padding: 10px 12px
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
  line-height: 16px
  max-width: 200px
  border-radius: 0
  span::before
    border: solid 1px #cdcdcd
  h4
    font-weight: 800
    margin-bottom: 5px
  p,ul
    color: $parso-super-strong-gray
  ul
    margin: 0
    padding: 0 0 0 15px
    li
      margin-bottom: 2px
  .MuiTooltip-arrow
    color: $parso-white
