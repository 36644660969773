// Colors
$parso-light-low: #FFEDB4
$parso-light-medium: #FFD1B0
$parso-light-high: #FFBCBC
$parso-danger: #E0778A
$parso-danger-light: #CF706F
$parso-light-danger: rgba(224, 119, 138, 0.6)
$parso-purple: #AE92CC
$parso-super-strong-gray: #4A4A4A
$parso-high-gray: #525252
$parso-medium-high-gray: #676a6c
$parso-medium-gray: #9B9B9B
$parso-strong-gray: #979797
$parso-disabled-gray: #EFEFEF
$parso-gray: #A9A9A9
$parso-secund-gray: #E5E5E5
$parso-bar-gray: #C8C5C5
$parso-medium-light-gray: #C4C4C4
$parso-white: #F9F9F9
$parso-text-error: #CD2A26
$parso-modal-gray: #F8F8F8
$parso-background: #E1E1E1
$parso-super-light-gray: #D8D8D8
$parso-light-gray: #F0F0F0
$parso-super-light-blue: #EEF2FF
$parso-light-blue-gray: #F7F7F7
$parso-light-green: #ECFAF3
$parso-light-blue: #B2BEE5
$parso-light-blue-light-gray: #F1F5FA
$parso-medium-blue: #96A8E0
$parso-blue: #6C85D3
$parso-gray-blue: #5F74B7
$parso-blue-strong: #50629C
$parso-yellow: #E5D166
$parso-yellow-strong: #E28834
$parso-yellow-medium: rgba(226, 136, 52, 0.25)
$parso-black: #050505
$parso-light-black: #05050c
$parso-box-blue: #F5F8FF
$parso-hover-button: #F3F3F3
$parso-red-strong: #FF0000
$parso-black-text: #373737
$parso-black-ligth-text: #6F6F6F
$parso-line-bar-gray: #DBDBDB
$parso-high-gray-blue: #4A4A68
$parso-drag-drop-blue: #D2D2D2
$parso-download-blue: #009EF6
$parso-semi-black: #201F1E
$parso-green-blue: #1D8099
$parso-shadow-color: rgba(146, 146, 146, 0.5)
$parso-warning: #CB7B01

$parso-pending: #F39C12
$parso-approved: #74BAB9
$parso-denied: #0E0E2C
$parso-success: #75D385
$parso-success-strong: #31D0AA

// BreakPoints
$sm: 600px
$md: 900px
$lg: 1200px
$xl: 1536px

// Default Theme
:root
  --primary: #6C85D3
  --secundary: #50629C
  --tertiary: #EEF2FF
