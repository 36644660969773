@import '../../variables'

.LoadPage
  background: $parso-blue
  height: 100vh
  .box-loading
    padding: 0 30px
    max-width: 500px
    height: 185px
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    text-align: center
    svg
      margin-bottom: 64px
