@import '../../../variables'
.dish-selection-modal
	h2
		font-family: 'Nunito Sans'
		font-style: normal
		font-weight: 800
		font-size: 22px
		line-height: 30px
		display: flex
		align-items: center
		color: $parso-high-gray
		flex-direction: column

	.dialog-detail-content
		padding: 40px 100px
		display: flex
		flex-direction: column
		align-items: center
		
	.dishes-modal-header
		width: 100%
		display: flex
		justify-content: space-between
		align-items: baseline
		gap: 5vw

	.MuiDialog-paper
		width: 100%
		height: 100%
		margin: 0

	.dishes-cards-container
		padding: 0px !important
	
	.dishes-modal-footer
		width: 100%
		height: 100px
		overflow: hidden
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
	
	.dishes-modal-footer-button
		width: 143px
		height: 52px
		padding: 12px 24px
		font-size: 18px
	
	.tabs-container
		width: 100%
		.MuiTabs-flexContainer
			padding: 0 24px
		
		.tab-item
			border-bottom: 2px solid $parso-light-gray
			
	.no-dishes
		.info-alert
			width: 82px
			height: 82px
			color:#e35c5c

		h1
			font-size: 20px
			font-weight: 700
			color: $parso-black
			text-align: center
		
		button
			padding: 12px 24px
			display: flex
			justify-content: center
			align-items: center
			gap: 8px
			font-size: 18px
			font-weight: 700
			font-family: 'Nunito Sans'
			border-radius: 8px
			width: 300px
	
	@media screen and (min-width: $sm)
		.tabs-container
			.MuiTabs-flexContainer
				justify-content: center
		
	
			
