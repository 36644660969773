@import '../../variables'

.QR
  .qr-wrapper
    width: 316px
    margin: auto auto 30px
    img
      margin: 29px 76px

  h4
    font-family: "Nunito Sans"
    font-size: 20px
    font-style: normal
    font-weight: 700
    line-height: normal
    text-align: center
    &.error
      color: black
