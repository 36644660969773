@import '../../../variables'

.swipeable-calendar.MuiDrawer-root > .MuiPaper-root
  height: 640px
  overflow: scroll
  borderRadius: 5px 5px 0 0

.SwipeableCalendar
  max-width: 321px
  margin: auto
  .mini-info
    color: $parso-black-ligth-text
    font-size: 11px
    margin-bottom: 8px
  .switch-multi-reservation-wrapper
    text-align: center
    .switch-multi-reservation
      margin: 0 0 28px 0
  .PrivatePickersSlideTransition-root.css-dhopo2
    min-height: 249px
  .badge-calendar
    position: relative
    &.selected > button
      background: var(--primary)
      color: white
    .MuiBadge-badge
      color: #bfbfbf
      position: absolute
      top: 16px
      right: 20px
      font-size: 18px
      font-weight: bold
      cursor: pointer
      padding: 0
      text-align: center
      height: 2px
      width: 2px
      display: block

  .message
    margin-top: 17px
    margin-bottom: 10px
    font-style: normal
    font-weight: 500
    font-size: 11px
    line-height: 20px
    letter-spacing: -0.0041em
    height: 40px
    overflow: hidden
    display: block
    align-items: center

  .calendar-footer
    position: relative
    width: 279px
    margin: auto
    .MuiAlert-root
      border-left: solid 1px
      border-radius: 0 4px 4px 0
    .MuiButton-root
      margin-top: 35px

  @media screen and (min-width: $md)
    margin: 30px 30px 26px
