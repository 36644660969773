@import '../../../variables'

.ModalNotification
  text-align: center
  .MuiButtonBase-root
    font-weight: bold
    border-radius: 8px

  .MuiPaper-root
    border-radius: 16px

  .main-ico
    color: $parso-green-blue

  h1
    color: $parso-green-blue
    font-size: 20px

  &.WARNING
    .main-ico
      color: $parso-warning
    h1
      color: $parso-warning

  p
    font-size: 16px
    font-weight: 200
    max-width: 328px
    color: $parso-black-ligth-text

  .detail
    max-height: calc(100vh - 417px)
    overflow: scroll
    padding-bottom: 24px

  .reverse
    transform: rotate(180deg)
