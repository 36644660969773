@import '../../variables'

.MuiPaper-root .MuiDialogContent-root.dialog-info-visitor
  text-align: center
  .detail-remove-info
    background: transparent
    margin-bottom: 20px



@media screen and (min-width: $md)
  .MuiFormControl-root
    .select-input
      width: 169px
  
  #visitor-detail-footer
    width: 60%
  
  .select-input
    width: 36%
