.attendees-option
  width: 250px
  .icon-person
    fill: #D2D2D2
  label
    font-size: 12px
    font-weight: 600
    line-height: normal
  span
    font-weight: 400
    font-size: 12px
