
@import '../../../variables'

$top-position-menu: 51px

.Menu
  &.MuiDrawer-root
    top: $top-position-menu

  .MuiPaper-root.MuiDrawer-paper
    padding: 33px 23px
    width: 304px
    background: #F7F7F7
    box-shadow: 0px 1px 8px rgba(162, 162, 162, 0.5)
    top: $top-position-menu

  .MuiButtonBase-root
    padding-left: 0
    padding-right: 0
    color: var(--primary)
    font-size: 16px
    font-weight: 500

  .MuiBackdrop-root
    top: $top-position-menu

  .close-menu-icon
    float: right
    cursor: pointer
    z-index: 1
    width: 40px

  @media screen and (min-width: $md)
    &.MuiDrawer-root
      top: 0px

    .MuiPaper-root.MuiDrawer-paper
      padding: 80px 23px 0 23px
      top: 0px

    .MuiBackdrop-root
      top: 0px
