@import '../../variables'

.Reservations
  .new-reservation-footer
    box-sizing: border-box
    width: 100%
    position: fixed
    bottom: 0
    left: 0
    background: white
    height: 90px
    padding: 24px 0
    text-align: center
    box-shadow: 0px 0px 6px $parso-shadow-color
    button
      width: 100%
      max-width: 311px

.MuiPaper-root
  .MuiDialogContent-root.dialog-detail-content
    padding: 14px 20px
    overflow-x: clip
    h2.dialog-title
      font-weight: 800
      font-size: 20px
      color: var(--secundary)

    h3.dialog-title
      font-weight: 800
      font-size: 14px
      vertical-align: middle

      .MuiButtonBase-root.arrow-back-button
        padding-right: 22px

      .dialog-vehicle
        vertical-align: middle
        margin-right: 16px
        padding-bottom: 5px

    h4.dialog-subtitle
      margin-top: 2px
      font-size: 14px
      color: $parso-high-gray
      margin-left: 37px
      svg
        margin-right: 10px

    .detail-hr.detail-hr
      border-color: var(--tertiary)
      margin: 13px 0 20px
      transform: scaleX(1.26)

    .detail-footer
      position: relative
      bottom: 0px
      padding: 0 28px 28px
      width: calc(100% - 72px)
      background: white
      &.workplace
        align-items: center
        width: 100%
        padding: 0
        
  .MuiDialogContent-root.dialog-info-content
    padding: 18px 24px
    max-width: calc(100vw - 100px)
    p
      font-size: 16px
    .detail-header
      color: var(--secundary)
      text-align: center
      margin-bottom: 19px
      font-size: 20px
      max-width: 650px
      svg
        display: block
        margin: auto
        margin-bottom: 6px
        width: 50px
        height: 50px
    .detail-list
      margin-bottom: 20px
      li
        margin-bottom: 4px
        line-height: 20px
    .detail-hr, p
      display: none
    p.enabled
      font-weight: 400
      font-size: 12px
      line-height: 16px
      text-align: center
      display: block
      margin: 19px 0
    .detail-footer
      justify-content: center
      min-width: 200px
      button
        width: 100%
    .order-detail-list
      display: flex
      align-items: center
      ul
        padding-left: 24px
      li
        list-style-type: disc
        padding-left: 0
        margin: 0 !important
      span
        margin: 0
    
  .attendees, .meeting-url
    display: flex
    align-items: center

  .attendees-list, .meeting-url-list
    display: flex
    flex-direction: column
    margin-left: 10px
    color: $parso-denied
    ul
      padding: 0
      margin-top: 5px
      list-style: none !important
      li
        margin-bottom: 5px !important
  
  .cafeteria-detail-list-wrapper
    padding: 24px
    margin-bottom: 60px
    .detail-list
      margin-bottom: 0
      li
        margin-bottom: 8px
        line-height: 22px
      #order-data
        font-weight: 600
        &:last-child
          list-style-type: none
      #cafeteria-order-list
        display: flex

  .attendees-error-message
    border-radius: 0px 8px 8px 0px
    border-left: 1.5px solid #CD2A26
    background: rgba(207, 112, 111, 0.10)
    color: $parso-danger-light
    display: flex
    padding: 8px 10px
    align-items: flex-start
    gap: 10px
    align-self: stretch
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-top: 8px


@media screen and (min-width: $md)

  .MuiPaper-root
    .MuiDialogContent-root.dialog-detail-content
      padding: 38px 63px
      box-sizing: border-box

      h3.dialog-title
        display: inline-block

      h4.dialog-subtitle
        display: inline-block

      .detail-footer
        left: 0
        right: 0
        margin: auto

        &.workplace
          max-width: initial
          display: flex
          justify-content: flex-end
          button
            width: 316px

    .MuiDialogContent-root.dialog-info-content
      padding: 32px 75px 30px
      width: 100%
      box-sizing: border-box
      display: flex
      flex-direction: column
      align-items: center
      .detail-header
        margin-bottom: 16px

      .detail-hr.detail-hr
        margin: 10px 0 12px
        border-color: var(--tertiary)
        display: block
        transform: scaleX(1.3)

      .detail-remove-info
        padding: 10px 13px
        border-radius: 4px
        margin: 12px 0 32px
        display: flex
        width: 100%
        max-width: 450px
      
      .detail-list
        margin-bottom: 0
        li
          margin-bottom: 8px
          line-height: 22px

      p
        color: $parso-high-gray
        display: block
        text-align: center
      .detail-footer
        width: 80%
        height: 50px
        left: 0
        right: 0
        margin: auto
        button
          border-radius: 8px
          padding-top: 0
          padding-bottom: 0
