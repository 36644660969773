.MuiPaper-root.header
  box-shadow: none
  .header-toolbar
    min-height: 51px
  .header-options
    border-radius: 5px
    .username
      font-size: 15px
      padding: 5px 0 0 0
      margin: 0 0 0 10px
  .logo-img-wrapper
    max-width: 164px
    max-height: 30px
    overflow: hidden
    .logo-img
      width: 100%
      max-width: 164px
      max-height: 30px
      height: auto
    path
      fill: white
