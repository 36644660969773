@import '../../../variables'

.filters
  margin-bottom: 16px

  label
    display: flex
    align-items: center
    font-weight: 500
    font-size: 13px
    line-height: 18px
    color: $parso-high-gray

  .MuiOutlinedInput-root.MuiOutlinedInput-root
    height: 33px
    box-shadow: 0px 0.769939px 3.07975px $parso-background
    border-radius: 4px

  .MuiInputAdornment-root
    color: #C4C4C4
    svg
      width: 15px

  .MuiInputBase-input
    font-style: italic
    font-weight: 400
    font-size: 13px
    line-height: 18px
    color: #525252

  .cp-select
    width: 140px
    font-size: 13px
    background: #FFFFFF
    border: none
    box-sizing: border-box
    border-radius: 4px
    .MuiSelect-select
      padding-left: 8px
  
  .new-reservation-filter-button
    display: none

  @media screen and (min-width: 1070px)
    .new-reservation-filter-button
      display: flex

    .new-reservation-filter-min-button
      display: none

  @media screen and (min-width: $md)
    margin-bottom: 30px
    .cp-select
      width: 150px
      font-size: 13px
