@import '../../variables'

.New-reservation .new-reservation .div-reservation.healt-form h2
  margin-bottom: 10px
  display: block
  color: $parso-high-gray

  &:not(:first-of-type)
    margin-top: 25px

.New-reservation .new-reservation .div-reservation.healt-form .form-wrapper
  padding-top: 0
