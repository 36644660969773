@import '../../../variables'

.TimeRangeSelector
  height: auto
  max-height: 572px
  overflow-y: scroll
  overflow-x: hidden
  min-width: 300px
  position: relative

  .leyend-my-calendar
    display: none
    position: sticky
    left: 70px
    top: 0
    z-index: 1
    background: white
    & > div
      box-sizing: border-box
      background: $parso-disabled-gray
      border: 1px solid #E0E0E0
      color: $parso-black-ligth-text
      font-size: 14px
      text-align: center
      border-radius: 8px 8px 0px 0px
      &:first-of-type
        width: calc(100% - 250px)
      &:last-of-type
        width: 180px

  .TimeRangeSelector-hours-wrapper
    position: relative
    width: 100%
    background-color: white
    background-size: var(--ONE_HOUR) var(--ONE_HOUR)
    background-image: repeating-linear-gradient(0deg, $parso-light-blue-gray, $parso-light-blue-gray 1px, white 1px, white)
    .TimeRangeSelector-hours
      label
        color: $parso-medium-gray
        font-size: 15px
        font-weight: 300
        font-style: normal
        height: var(--ONE_HOUR)
        padding-right: 5px
        box-sizing: border-box
        width: 65px
        background: white
        text-align: right
    .TimeRangeSelector-new-event-wrapper
      position: absolute
      margin-left: 70px
      width: 100%
      min-height: var(--FIFTEEN_MINUTES)
      top: 0

      &.react-draggable-dragging
        .TimeRangeSelector-new-event .handle
          cursor: grabbing

      .TimeRangeSelector-new-event
        background: red
        border-radius: 8px
        border-top: 2px solid $parso-success
        border-bottom: 2px solid $parso-success
        border-left: 3px solid $parso-success
        background: $parso-light-green
        position: relative
        box-sizing: border-box
        padding: 8px 10px
        width: calc( 100% - 70px)

        .react-resizable-handle
          width: 17px
          height: 13px
          display: block
          background: $parso-success
          position: absolute
          z-index: 1
          border-radius: 50%
          cursor: n-resize
          bottom: -8px
          right: 20px

        &.has-collision
          background: #FFD4D9
          border-color: $parso-text-error

          .react-resizable-handle
            background: $parso-text-error

        .handle
          height: 100%
          cursor: grab
          overflow: hidden

          .TimeRangeSelector-info
            cursor: text

    .TimeRangeSelector-prev-reservations
      width: calc( 100% - 70px)
      position: absolute
      left: 70px
      border: 1px solid $parso-drag-drop-blue
      background: #FFEBED
      padding: 8px 10px
      font-size: 14px
      color: $parso-gray-blue
      box-sizing: border-box
      overflow: hidden

      &.event
        background: #E9F5F8
        border-color: #d0ecf4
        color: $parso-green-blue
        left: auto
        right: 0
        width: 180px
        &:hover
          z-index: 1
          box-shadow: 0 0 10px 0px $parso-drag-drop-blue

      &.disabled-time
        background: $parso-disabled-gray

  &.show-own-calendar
    .leyend-my-calendar
      display: flex

    .TimeRangeSelector-prev-reservations
      width: calc(100% - 250px)

    .TimeRangeSelector-hours-wrapper
      .TimeRangeSelector-new-event-wrapper
        .TimeRangeSelector-new-event
          width: calc(100% - 250px)
