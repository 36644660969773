@import '../../../variables.sass'

.Success-reservation
  .success-box
    background: white
    margin: auto
    width: 316px
    height: 343px
    margin-top: 60px

    .success-icon.success-icon,
    .info-icon.info-icon,
    .error-icon.error-icon
      color: $parso-success
      margin: auto
      display: block
      font-size: 32px

    .info-icon.info-icon
      color: $parso-green-blue

    .error-icon.error-icon
      color: #5F75B9

    .success-title
      color: #1C1939
      font-weight: 800
      font-size: 17px
      text-align: center
      line-height: 27px
      margin: 9px 0 7px

  &.info-box
    .success-icon.success-icon
      color: $parso-green-blue
    .success-title
      color: $parso-green-blue
    #success-content
      text-align: center
    .success-footer
      color: $parso-green-blue

  .detail-list-wrapper
    margin-bottom: 16px

    &.detail-list-wrapper-erros
      margin-top: 58px
      position: relative

    .header-detail-list
      position: absolute
      top: -41px
      left: 0
      background: #EFEFEF
      padding: 8px 16px 12px 16px
      align-items: center
      color: #CD2A26
      border-radius: 4px

    .detail-list
      margin-bottom: 0
      li
        margin-bottom: 7px
      p
        margin-bottom: 15px
        &:last-of-type
          margin-bottom: 0

    .attendees
      display: flex
      align-items: center

    .attendees-list
      display: flex
      flex-direction: column
      margin-left: 10px
      color: $parso-denied

    .attendees-list ul
      padding: 0
      margin-top: 5px

  .success-footer
    margin-top: 40px

  header
    display: none

  .MuiButton-root.MuiButton-root
    font-family: 'Avenir', sans-serif
    letter-spacing: 1px
    text-transform: uppercase

  @media screen and (min-width: $md)
    .success-box
      height: 425px
      margin-top: 30px
      .detail-list-wrapper
        background: #EFEFEF
        padding: 10px 13px
        border-radius: 4px

      .success-icon.success-icon,
      .error-icon.error-icon
        font-size: 40px

      .success-title
        font-size: 22px
        line-height: 34px
        margin: 22px 0 36px

      .detail-list
        li
          font-size: 14px
          margin-bottom: 8px
          display: flex
          align-items: flex-start

    header
      display: block
