@import '../../../variables'

.DragAndDrop
  position: relative
  width: 100%
  form
    height: 349px

  .drag-file-element
    position: absolute
    width: 100%
    height: 100%
    top: 0px
    right: 0px
    bottom: 0px
    left: 0px

  .input-file-upload
    display: none
  .file-detail
    .file-detail-name
      border-radius: 4px
      background-color: $parso-light-green
      height: 56px
      padding-left: 22px
      margin: 0 0 20px 0
      font-size: 14px
      p
        padding-left: 20px
        width: 100%
        vertical-align: "center"
      button
        height: 100%
        background: #FFF1F4

  &.disabled
    .label-file-upload
      background: $parso-light-gray
      color: rgba(0, 0, 0, 0.26)
      svg path
        fill: rgba(0, 0, 0, 0.12)

  .label-file-upload
    height: 100%
    display: flex
    align-items: center
    justify-content: center

    background: $parso-super-light-blue
    border: 2px dashed $parso-drag-drop-blue
    border-radius: 4px

    &.drag-active
      background-color: #ffffff

    .upload-icon
      width: 121px
