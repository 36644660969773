@import '../../../variables'
.counter-container
    display: flex
    align-items: center
    gap: 12px
    background-color: $parso-light-blue-light-gray
    border-radius: 8px
    padding: 8px

    .control-button
        width: 32px
        height: 32px
        border-radius: 8px
        box-shadow: none
    
    .counter-input
        input
            font-weight: 600
            font-size: 18px
            padding: 8.5px 0
        fieldset
            border: none
        
